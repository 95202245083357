/**
 * @generated SignedSource<<ee51ab0a9099e84d44dbddaefb9beebd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Member",
    "kind": "LinkedField",
    "name": "currentMember",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasDeviationKanbanAsDefault",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CurrentMemberSettingsQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CurrentMemberSettingsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "1f8318747023c3ea99139f81ca47c78b",
    "id": null,
    "metadata": {},
    "name": "CurrentMemberSettingsQuery",
    "operationKind": "query",
    "text": "query CurrentMemberSettingsQuery {\n  currentMember {\n    id\n    hasDeviationKanbanAsDefault\n  }\n}\n"
  }
};
})();

node.hash = "fb334c37024fb3630cc24d6e9b1c8191";

module.exports = node;
